@use "../config/" as *;
.waf-listing {
    &.related-list {
        @extend %relative;
        @extend %pure-white-900-bg;
        @extend %m-4;
        @extend %half-radius;
        @extend %px-4;
        @extend %pt-4;
        @extend %pb-12;
        .waf-head {
            @include drop-arrow();
            @extend %pb-4;
            --_border-color: var(--secondary-800);
            --_more-color: var(--secondary-800);
            .head-tab {
                bottom: var(--space-4);
                @extend %px-4;
                @extend %w-100;
                @extend %pos-x-center;
                a {
                    height: 3.4rem;
                    justify-content: center;
                }
            }
        }
        .article {
            &-list {
                @include listing-card(horizontal);
                @include card-count(1, var(--space-4), true);
                @extend %my-4;
                .article-content {
                    position: relative;
                }
                .article-thumbnail {
                    width: 11rem;
                }
                .article-content {
                    width: calc(100% - 11rem);
                }
            }
            &-item {
                border-bottom: .1rem solid var(--neutral-50);
                border-radius: 0;
                @extend %pb-4;
            }
            &-content {
                padding-block: 0;
                padding-inline: var(--space-4) 0;
            }
            &-title {
                font-weight: 700;
                @extend %mt-4;
                @extend %neutral-800;
                @extend %font-14-pb;
                @include truncate(2,14);
            }
            &-wrap {
                @include flex-config(flex, null, center, null);
            }
            &-thumbnail {
                flex-shrink: 0;
            }
            &-meta {
                @extend %mt-auto;
                .meta {
                    @extend %font-10-pm;
                    @extend %neutral-500;
                }
                .meta-date {
                    flex-shrink: 0;
                }
                .meta-author {
                    white-space: nowrap;
                    overflow: hidden;
                }
                .meta-category {
                    @extend %secondary-300;
                    @include position(var(--space-0),null,null,var(--space-4));
                }
            } 
        }
        .item-type-video {
            .article-info {
                display: block;
            }
            .timestamp {
                width: max-content;
                max-width: 6.5rem;
                gap: var(--space-2);
                height: 2rem;
                @extend %px-2;
                @include position(unset, null, var(--space-2), -10rem);
                @extend %primary-300-bg;
                @extend %half-radius;
                @extend %font-10-pb;
                @extend %neutral-800;
                @extend %flex-n-c;
                &::before {
                    @include icon(video, 12);
                }
            }
            .item-type-icon {
                @extend %d-none;
            }
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        &.related-list {
            margin-inline: 0;
        }
    }
}
@include mq(col-lg) {
    .waf-listing {
        &.related-list {
            margin-inline: 0;
            .waf-head {
                .head-tab {
                    a {
                        background: transparent;
                    }
                }
            }
        }
    }
}