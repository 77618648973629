@use "../config/" as *;
.graph {
    @extend %p-4;
    @extend %pure-white-900-bg;
    .tabs-secondary {
        @extend %pb-3;
        @extend %gap-3;
        .tab {
            &-name {
                border: .1rem solid var(--neutral-600);
                min-height: 3rem;
                @extend %half-radius;
                &.tab-active {
                    border: .1rem solid var(--primary-300);
                }
            }
        }
    }
    .tab-title {
        @extend %neutral-50-bg;
        @extend %p-4;
        .sub-title {
            @extend %font-14-pb;
            @extend %capitalize;
            @extend %neutral-800;
        }
    }
    .radio-box-wrap {
        flex: 1;
        @extend %gap-2;
        @extend %flex-c-c;
        @extend %flex-wrap;
    }
    .tab-legends {
        @extend %my-5;
        @extend %gap-4;
        @extend %flex-n-c;
        .shortname {
            @extend %d-none;
        }
        .legend-item {
            @extend %w-50;
            @extend %gap-1;
            @extend %flex-n-c;
        }
        .team {
            &-a {
                justify-content: flex-end;
                .color {
                    @extend %primary-300-bg;
                }
            }
            &-b {
                .color {
                    @extend %secondary-600-bg;
                }
            }
        }
        .color {
            width: 1.5rem;
            aspect-ratio: 1/1;
            border-radius: .2rem;
            @extend %flex;
        }
        .name {
            @extend %neutral-800;
            @extend %capitalize;
            @extend %font-12-pm;
        }
    }
    .manhattan-section {
        .score-line {
            height: 36rem;
        }
        .graph-listing {
            height: calc(100% + 3rem);
            bottom: var(--space-5-neg);
        }
        .graph-value {
            width: 4rem;
        }
    }
    .innings-progression-section {
        @extend %pb-4;
        .inner-wrap {
            @extend %relative;
        }
        .score-line {
            span {
                @extend %capitalize;
                @extend %neutral-800;
                @extend %font-10-pb;
            }
            .score-label {
                @include position(null, null, var(--space-9), -.6rem);
            }
            .over-label {
                bottom: var(--space-3-neg);
                @extend %pos-x-center;
            }
        }
    }
}
.spider-section {
    .svg {
        &-section {
            width: 30rem;
            aspect-ratio: 1/1;
            margin: var(--space-10) auto;
            @extend %relative;
            @extend %flex-c-n;
        }
        &-graph {
            width: 28rem;
            aspect-ratio: 1/1;
            @include background(null, "svg/spider.svg", center / contain no-repeat);
        }
        &-div {
            width: 22rem;
            height: 22rem;
            overflow: hidden;
            @extend %circle-radius;
            @extend %pos-center;
            svg {
                @extend %w-100;
                @extend %h-100;
            }
        }
    }
    .zone-listing {
        @extend %absolute;
        @extend %w-100;
        @extend %h-100;
        .zone-item {
            position: absolute;
            .data {
                @extend %neutral-800;
                @extend %font-14-pb;
            }
        }
        .item-eight {
            top: 7.5rem;
            left: -0.5rem;
        }
        .item-one {
            top: -1rem;
            left: 7rem;
            transform: rotate(340deg);
        }
        .item-two {
            top: -1rem;
            right: 7rem;
            transform: rotate(30deg);
        }
        .item-three {
            right: -.5rem;
            top: 7rem;
        }
        .item-four {
            right: -1rem;
            bottom: 9rem;
        }
        .item-five {
            bottom: 0.5rem;
            right: 7rem;
        }
        .item-six {
            bottom: 0.5rem;
            left: 7rem;
        }
        .item-seven {
            bottom: 9rem;
            left: -1rem;
        }
    }
    .controls {
        &-list {
            @extend %flex;
        }
        &-item {
            flex: 1;
            height: 4.8rem;
            @extend %gap-1;
            @extend %flex-column-c-c;
            .number,
            .runs {
                @extend %font-12-pm;
                @extend %pure-white-900;
            }
            &-one {
                @extend %spidern-1s-bg;
            }
            &-two {
                @extend %spidern-2s-bg;
            }
            &-three {
                @extend %spidern-3s-bg;
            }
            &-four {
                @extend %spidern-4s-bg;
            }
            &-five {
                @extend %spidern-5s-bg;
            }
        }
    }
    .radio-box-wrap {
        @extend %flex;
        @extend %gap-4;
        @extend %my-6;
        .radio-box {
            flex: 1;
            flex-basis: 25%;
            display: flex;
            // &:first-child {
            //     @extend %text-right;
            // }
        }
        .radio-container {
            cursor: pointer;
            user-select: none;
            @extend %relative;
            @extend %pl-6;
            @extend %capitalize;
            @extend %font-12-pm;
            @extend %neutral-800;
            @extend %flex;
            input {
                opacity: 0;
                left: 0;
                cursor: pointer;
                @extend %d-none;
                @extend %pos-y-center;
                &:checked {
                    ~ .checkmark {
                        border: 0.1rem solid var(--secondary-600);
                        &:after {
                            @include icon(checkmark, 10);
                        }
                    }
                }
            }
            .checkmark {
                height: 1.5rem;
                aspect-ratio: 1/1;
                border: 0.1rem solid var(--primary-300);
                left: 0;
                border-radius: .2rem;
                @extend %pos-y-center;
                &:after {
                    @extend %pos-center;
                }
            }
        }
    }
}
.manhattan-section {
    --_bar-top-space: 6rem;
    padding-bottom: var(--_bar-top-space);
    .inner-wrap {
        height: 40rem;
        @extend %relative;
    }
    .score-line {
        height: 40rem;
        border-left: .1rem solid var(--neutral-100);
        border-bottom: .1rem solid var(--neutral-100);
        padding-top: 8rem;
        @extend %ml-10;
        @extend %relative;
        .lable {
            @extend %capitalize;
            @extend %neutral-800;
        }
    }
    .line-wrap {
        height: 4rem;
        border-top: .1rem dashed var(--neutral-100);
        @extend %flex-n-fs;
        .lable {
            margin: var(--space-3-neg) 0 0 var(--space-5-neg);
            @extend %font-10-pb;
        }
    }
    .score-label {
        @include position(null, null, var(--space-1-neg), var(--space-9-neg));
        .lable {
            @extend %font-12-pb;
        }
    }
    .graph {
        &-listing {
            width: calc(100% - var(--_bar-top-space));
            height: calc(100% + 7rem);
            padding-bottom: var(--_bar-top-space);
            padding-top: 9rem;
            @include position(null, null, calc(var(--_bar-top-space) * -1), 6rem);
        }
        &-item {
            width: 4rem;
        }
        &-value {
            width: 5rem;
            z-index: var(--z-graph-value);
            height: 3rem;
            @extend %pure-white-900-bg;
            @extend %flex-c-c;
            @extend %relative;
            @extend %neutral-800;
            @extend %font-10-pm;
        }
        &-info {
            z-index: var(--z-manhattan-tooltip);
            width: 15rem;
            height: auto;
            flex-direction: column;
            @include position(0, null, null, var(--space-7));
            @extend %d-none;
            @extend %half-radius;
            @extend %primary-300-bg;
            @extend %p-3;
            &::after {
                content: '';
                left: -0.8rem;
                border-style: solid;
                border-width: .6rem .8rem .6rem 0;
                border-color: transparent var(--primary-300) transparent transparent;
                @extend %pos-y-center;
            }
            .text {
                @extend %neutral-800;
                @extend %font-12-pm;
            }
        }
    }
    .bar-wrap {
        overflow: visible;
        @extend %flex-c-fe;
        @extend %h-100;
        .team {
            &-a {
                @extend %primary-300-bg;
                &:after {
                    box-shadow: .2rem 0 .4rem 0 clr(neutral-900, 2);
                    @extend %primary-700-bg;
                }
                &:hover {
                    .graph-info {
                        @extend %flex;
                    }
                }
            }
            &-b {
                @extend %secondary-600-bg;
                &:after {
                    box-shadow: .2rem 0 .4rem 0 clr(neutral-900, 2);
                    @extend %secondary-800-bg;
                }
                &:hover {
                    .graph-info {
                        @extend %flex;
                    }
                }
            }
        }
    }
    .bar-graph-line {
        width: 1.2rem;
        margin: 0 .3rem;
        transition: all 1s ease;
        align-self: end;
        cursor: pointer;
        @extend %relative;
        @extend %secondary-600-bg;
        &::after {
            content: '';
            width: .6rem;
            transform: skew(0deg, 35deg);
            @extend %h-100;
            @extend %secondary-800-bg;
            @include position(.3rem, null, null, 100%);
        }
    }
    .wicket-wrap {
        margin-top: -6.5rem;
        height: 6.5rem;
        @extend %gap-1;
        @extend %flex-column-fe-n;
        .wicket-text-label {
            width: 1.8rem;
            height: 1.8rem;
            @extend %flex-c-c;
            @extend %primary-900-bg;
            @extend %uppercase;
            @extend %font-10-pb;
            @extend %pure-white-900;
            @extend %circle-radius;
        }
    }
    .swiper-button {
        &-next,
        &-prev {
            position: unset;
            @extend %transparent-bg;
        }
        &-wrap {
            padding-top: 3rem;
            margin-left: -6rem;
            @extend %flex-c-c;
        }
        &-text {
            @extend %px-5;
            @extend %capitalize;
            @extend %font-14-pb;
        }
    }
    .swiper-slide-thirdlast {
        .graph-info {
            width: 14rem;
        }
    }
    .swiper-slide-secondlast,
    .swiper-slide-last {
        .graph-info {
            left: auto;
            right: var(--space-5);
            &::after {
                left: auto;
                right: -.8rem;
                border-width: .6rem 0 .6rem .8rem;
                border-color: transparent transparent transparent var(--primary-300);
            }
        }
    }
}
@include mq(col-md) {
    .graph {
        .spider-section {
            .head {
                @include flex-config(flex, null, space-between, center);
            }
            .waf-select-box {
                width: 40%;
                flex: unset;
            }
        }
        .innings-progression-section {
            .score-line {
                span {
                    font-size: 1.1rem;
                }
                .score-label {
                    left: var(--space-2-neg);
                    bottom: var(--space-3);
                }
                .over-label {
                    bottom: var(--space-6-neg);
                }
            }
        }
        .manhattan-section .score-label {
            bottom: var(--space-2-neg);
            left: var(--space-10-neg);
        }
    }
    .manhattan-section {
        .graph-value {
            width: 7.5rem;
        }
    }
}
@include mq(col-lg) {
    .spider-section {
        .svg-controls-section {
            width: 50%;
            margin-inline: auto;
        }
    }
    .manhattan-section {
        .graph {
            &-item {
                width: 6rem;
            }
            &-value {
                width: 6rem;
            }
        }
    }
    .graph {
        .manhattan-section {
            .graph-value {
                width: 7.5rem;
            }
        }
    }
}