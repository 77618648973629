@use "../config/" as *;
.player-stats {
    @extend %py-6;
    @extend %px-4;
    &.player-details {
        margin-inline: 0;
    }
    .description {
        flex-basis: 50%;
        box-sizing: border-box;
        @extend %flex-column-c-c;
        @extend %font-14-pb;
        @extend %h-100;
    }
    .stats-tabs {
        border-bottom: .1rem solid clr(neutral-300);
        @extend %mb-6;
    }
    .tab-item {
        border-radius: .4rem .4rem 0 0;
        flex: 1;
        min-height: 4rem;
        // @extend %py-4;
        // @extend %px-2;
        @extend %neutral-100-bg-2;
        &.active {
            @extend %primary-300-bg;
        }
        .btn-text {
            @extend %font-12-pb;
            @extend %secondary-800;
        }
        .tab-anchor {
            @extend %w-100;
            @extend %h-100;
        }
    }
    .debut {
        &-head {
            border-right: .1rem solid var(--neutral-1000);
            width: 14rem;
            @extend %p-4;
            @extend %flex-n-c;
            .title {
                @extend %font-12-pb;
                @extend %pb-0;
                @extend %mb-0;
            }
        }
        &-body {
            flex: 1;
            @extend %p-4;
            .description {
                align-items: flex-start;
            }
        }
        &-section {
            @extend %neutral-100-bg-2;
            @extend %flex;
            @extend %mb-6;
        }
    }
    .stats {
        &-head {
            @extend %flex-sb-c;
            @extend %mb-6;
            .title {
                border-bottom: 0;
                @extend %font-16-pb;
                @extend %uppercase;
                @extend %pb-0;
                @extend %mb-0;
            }
        }
        &-card {
            border: .1rem solid clr(neutral-100, 2);
            border-radius: .4rem;
            @extend %mb-6;
            @extend %py-6;
            @extend %px-4;
        }
    }
    .card {
        &-head {
            .title {
                border-bottom: .1rem solid var(--neutral-1000);
                @extend %font-16-pb;
                @extend %uppercase;
                @extend %pb-4;
                @extend %mb-0;
            }
        }
        &-item {
            @extend %flex-sb-c;
            @extend %py-6;
            // @extend %px-5;
            &:not(:first-child) {
                border-top: .1rem solid clr(neutral-100, 2);
            }
            &:last-child {
                @extend %pb-0;
            }
        }
        &-title {
            word-wrap: break-word;
            @extend %font-14-pm;
            @extend %neutral-600;
            @extend %w-50;
            span {
                @extend %d-block;
                @extend %mb-1;
            }
            .text {
                @extend %font-10-pr;
            }
        }
        &-count {
            word-wrap: break-word;
            @extend %font-16-pb;
            @extend %secondary-1000;
            @extend %text-right;
            @extend %w-50;
        }
    }
    .waf-select-box {
        @extend %pure-white-900-bg;
        @extend %p-0;
        @extend %flex-fe-c;
        &::before {
            content: unset;
        }
        .select-box-wrap {
            max-height: 0;
            width: 14rem;
            overflow-y: auto;
            box-shadow: 0 0 0.5rem 0 clr(neutral-900, 3);
            transition: max-height 0.5s ease-out;
            @include position(null, 0, null, unset);
            @extend %d-block;
            @extend %neutral-50-bg;
            @extend %half-radius;
        }
        .btn-text {
            @extend %font-12-pm;
        }
    }
    .dropdown-label {
        @extend %d-none;
    }
    .selected-title {
        border-radius: .4rem;
        border: .1rem solid var(--neutral-1000);
        width: 12rem;
        @extend %py-2;
        @extend %px-3;
        .selected-text {
            @extend %font-12-pb;
            @extend %capitalize;
        }
        &[aria-expanded=true] {
            ~ .select-box-wrap {
                max-height: 18rem;
            }
            &::after {
                rotate: 180deg;
            }
        }
        &::after {
            right: .4rem;
            @include icon(chevron-down, 12);
            @extend %secondary-800;
        }
    }
    .select-list {
        @extend %p-3-4;
        .list-item {
            text-transform: capitalize;
            @extend %p-0;
            @extend %font-14-pm;
            @extend %neutral-800;
        }
    }
}
@include mq(col-lg) {
    .player-stats {
        .tab-item {
            flex: unset;
            min-width: 14rem;
        }
        .description {
            font-size: 1.2rem;
        }
        .debut-section {
            margin-bottom: var(--space-4);
            // height: 3rem;
        }
        .debut-head {
            flex-basis: 10%;
            align-items: flex-start;
            padding-block: var(--space-2);
        }
        .debut-body {
            padding-block: var(--space-2);
        }
        .card {
            &-count {
                font-size: 1.8rem;
            }
            &-item {
                padding-inline: var(--space-5);
            }
        }
        .stats {
            &-head {
                margin-bottom: var(--space-4);
            }
            &-card {
                margin-bottom: 0;
            }
            &-card-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: var(--space-4);
            }
            &-card-batting,
            &-card-bowling {
                flex: 1;
            }
            &-card-fielding {
                flex-basis: 100%;
                padding-bottom: 0;
                .card {
                    &-body {
                        display: flex;
                        gap: var(--space-10);
                    }
                    &-item {
                        border: 0;
                        flex: 1;
                        @include separator(60%, y, .1rem, neutral-900);
                        &:last-child {
                            padding-bottom: var(--space-6);
                            &::after {
                                display: none;
                            }
                        }
                        &::after {
                            right: var(--space-5-neg);
                        }
                    }
                }
            }
        }
    }
}