@use "../config/" as *;
.waf-player {
  ::-webkit-scrollbar {
    width: .4rem;
  }
  position: relative;
  @extend %mb-5;
  &::before {
    content: '';
    height: 14.9rem;
    z-index: var(--z-patterns);
    // background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
    @include background(var(--secondary-800), "patterns/player-profile-bg.png", center / cover no-repeat);
    @extend %pos-tl;
    @extend %w-100;
  }
  .waf-head {
    @extend %d-none;
  }
  &.modal-active {
    .waf-footer {
      display: flex;
    }
  }
  .manhattan {
    &-section {
      border: .1rem solid hsl(var(--hsl-neutral-100) / 0.2);
      padding-bottom: 7rem;
      padding-right: var(--space-0);
      @extend %p-4;
      @extend %half-radius;
      &-runs {
        @extend %mb-6;
      }
      &.manhattan-section-wickets {
        .score-label {
          left: -5.2rem;
        }
      }
      .line-wrap {
        height: 8rem;
        &:nth-last-child(2) {
          border-top: 0;
          @extend %d-none;
        }
        .lable {
          margin: var(--space-2-neg) 0 0 var(--space-8-neg);
          @extend %font-12-pm;
        }
      }
      .score-line {
        height: 34rem;
        @extend %pt-5;
      }
      .score-label {
        left: -4rem;
        .lable {
          font-weight: 500;
        }
      }
      .inner-wrap {
        height: 34rem;
      }
      .graph {
        &-listing {
          left: 3.5rem;
          width: calc(100% - 3.5rem);
          // padding-top: 3rem;
          padding-top: 5rem;
          // height: calc(100% + 7rem);
          height: calc(100% + 9rem);
          @extend %pl-4;
        }
        &-value {
          @extend %font-12-pm;
          @extend %w-100;
        }
        &-info {
          width: 18rem;
          top: -5rem;
          // top: 0;
          @extend %py-4;
          @extend %px-2;
          .title {
            border-bottom: unset;
            padding-bottom: 0;
            @extend %font-10-pb;
            @extend %mb-2;
          }
          .list {
            @extend %flex-column;
            @extend %gap-2;
          }
          .item {
            border-bottom: 0.1rem solid var(--primary-200);
            height: 2.3rem;
            @extend %flex-sb-c;
          }
          .lable {
            @extend %font-10-pr;
            @extend %neutral-800;
          }
          .count {
            @extend %font-10-pb;
            @extend %secondary-1000;
          }
        }
      }
      .bar-graph-line {
        width: 1.3rem;
        margin: 0 0 0 -0.6rem;
        &:hover {
          .graph-info {
            @extend %flex;
          }
        }
      }
      .swiper {
        &-button-next {
          &::after {
            @include icon(p-arror-right);
          }
        }
        &-button-prev {
          &::after {
            @include icon(p-arrow-left);
          }
        }
        &-button-text {
          .label {
            @extend %font-12-pr;
          }
        }
        &-button-wrap {
          padding-top: var(--space-9);
        }
      }
    }
  }
  .related {
    &-news,
    &-photos,
    &-videos {
      margin-inline: 0;
      .waf-head {
        @extend %d-block;
        @extend %pb-6;
        .title {
          margin-bottom: 0;
        }
      }
    }
    &-videos {
      padding: 0;
      .waf-head {
        padding: 0 0 var(--space-4) 0;
      }
      .title {
        padding-bottom: 0;
        border-bottom: unset;
      }
      .waf-listing {
        .article-list {
          .img-box {
            @extend %half-radius;
          }
        }
      }
      .article-meta {
        .meta-category {
          @extend %secondary-800;
        }
      }
    }
    &-photos {
      .waf-listing {
        background-color: unset;
        @include listing-card(overlay);
        @extend %py-6;
        .article {
          &-list {
            flex-wrap: nowrap;
            @extend %pt-2;
            & > * {
              @extend %w-90;
            }
          }
          &-thumbnail {
            &::before {
              background: linear-gradient(0deg, var(--secondary-800) -8.39%, transparent 84.44%);
            }
            .item-type-icon {
              top: 0rem;
              left: 0rem;
              height: 3.3rem;
              border-bottom-left-radius: 0;
            }
          }
          &-content {
            background-color: unset;
          }
          &-title {
            font-size: 1.6rem;
            @extend %mt-0;
            @extend %pure-white-900;
          }
          &-meta {
            .meta-category {
              @extend %pure-white-900;
            }
          }
        }
      }
      .waf-head {
        .title,
        .head-wrap {
          padding-bottom: 0;
          border-bottom: unset;
        }
      }
      .layout-wrapper {
        padding-inline: 0;
      }
    }
  }
  .related-list {
    margin: 0;
    padding: 0 0 var(--space-12) 0;
    &.waf-listing {
      .article-list {
        gap: var(--space-6);
      }
      .waf-head {
        .head-tab {
          bottom: 0;
          padding-inline: 0;
        }
      }
    }
    .layout-wrapper {
      padding-inline: 0;
    }
    .article-list {
      margin: 0;
      padding: 0;
    }
    .article-meta {
      .meta {
        &-category {
          color: var(--secondary-800);
        }
      }
    }
  }
  .player-details {
    &:empty {
      display: none;
      padding: 0;
    }
    &.ad-one,
    &.ad-two,
    &.ad-three,
    &.ad-four,
    &.ad-five {
      padding: 0;
      margin: 0;
      // background: transparent;
      @extend %neutral-1100-bg;
    }
  }
}
.player {
  &-details {
    @extend %mb-4;
    &:not(:first-child) {
      @extend %half-radius;
      @extend %m-4;
      @extend %p-4;
      @extend %pure-white-900-bg;
    }
    &.trending-players {
      margin-inline: var(--space-0);
      &:empty {
        padding: 0;
        @extend %d-none;
      }
    }
    .title {
      border-bottom: 0.1rem solid var(--neutral-50);
      @extend %font-16-pb;
      @extend %uppercase;
      @extend %pb-4;
      @extend %mb-4;
    }
  }
  &-info-wrapper {
    @extend %text-center;
    @extend %pt-7;
    @extend %relative;
    &::before {
      content: '';
      height: 74%;
      z-index: var(--z-patterns);
      pointer-events: none;
      @extend %secondary-1000-bg;
      @extend %pos-bl;
      @extend %w-100;
    }
  }
  &-image {
    width: 15rem;
    height: 15rem;
    aspect-ratio: 1/1;
    border: 0.4rem solid var(--primary-300);
    @extend %secondary-50-bg;
    @extend %circle-radius;
    @extend %mx-auto;
    @extend %mb-4;
  }
  &-name {
    @extend %mb-6;
    .name {
      @extend %pure-white-900;
      @extend %d-block;
      @extend %text-center;
      @extend %font-24-pr;
      @extend %uppercase;
      &.last-name {
        font-weight: 700;
        @extend %mt-1;
      }
    }
  }
  &-country {
    @extend %mb-7;
    @extend %flex-c-c;
    @extend %gap-2;
    .country {
      @extend %neutral-50;
      @extend %font-16-pm;
      &-flag {
        width: 2.4rem;
        height: 1.5rem;
      }
    }
  }
  &-meta-item {
    @extend %flex-sb-c;
    &:not(:last-child) {
      @extend %mb-6;
    }
  }
  &-meta-title {
    @extend %font-10-pr;
    @extend %uppercase;
  }
  &-meta-value {
    max-width: 70%;
    @extend %font-12-pb;
    @extend %text-right;
    @extend %capitalize;
  }
  &-bio-wrap {
    max-height: 38.4rem;
    overflow-y: clip;
    @extend %relative;
    &::after {
      content: '';
      pointer-events: none;
      background: linear-gradient(180deg, hsl(var(--hsl-pure-white-900) / 0) 0%, var(--pure-white-900) 100%);
      @extend %pos-bl;
      @extend %w-100;
      @extend %h-50;
    }
    .toggle-bio {
      z-index: var(--z-view-more);
      @include position(null, 2.4rem, 2.4rem, null);
      @include btn-animation();
      &::after {
        content: unset;
      }
    }
  }
  &-bio {
    @extend %pb-2;
    .content {
      line-height: 1.5;
      @extend %font-12-pr;
    }
  }
  &-awards {
    --_line-number: 3;
    @extend %mx-3;
    @extend %half-radius;
    .title {
      border-bottom: .1rem solid var(--neutral-50);
      @extend %pb-3;
    }
    .awards {
      &-list {
        max-height: 25rem;
        overflow-y: auto;
      }
      &-item {
        height: 9rem;
        background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
        @extend %half-radius;
        @extend %gap-2;
        @extend %px-2;
        @extend %flex-n-c;
        &:not(:last-child) {
          @extend %mb-2;
        }
      }
      &-content {
        width: calc(100% - 9rem);
        line-height: 1.5;
        @include truncate-text(var(--_line-number));
        @extend %font-12-pb;
        @extend %primary-300;
      }
      &-thumbnail {
        width: 7.4rem;
        aspect-ratio: 1/1;
        @extend %transparent-bg;
        @extend %circle-radius;
        // @extend %pure-white-900-bg;
        img {
          @extend %font-0;
        }
      }
    }
  }
}
.represented-teams {
  height: auto;
  @extend %mx-3;
  @extend %half-radius;
  .title {
    border-bottom: .1rem solid var(--neutral-50);
    @extend %pb-3;
  }
  .teams {
    &-item {
      @extend %flex-n-c;
      @extend %gap-2;
      &:not(:last-child) {
        @extend %mb-4;
      }
    }
  }
  .team {
    @extend %font-12-pb;
    @extend %neutral-800;
    @extend %capitalize;
    &-flag {
      width: 4.1rem;
      height: 2.6rem;
      @extend %neutral-50-bg;
    }
  }
}
.social {
  &-wrap {
    height: 5.6rem;
    @extend %flex-c-c;
    @extend %w-100;
    @extend %pure-white-900-bg-1;
  }
  &-items {
    @extend %flex-c-c;
    @extend %gap-2;
    @extend %transparent-bg;
  }
  &-link-anchor {
    width: 2.5rem;
    height: 2.5rem;
    aspect-ratio: 1/1;
    @extend %circle-radius;
    @extend %flex-c-c;
    @extend %primary-100-bg;
  }
  &-link-image {
    max-width: 100%;
    max-height: 100%;
  }
}
.waf-footer {
  .modal {
    &-window {
      width: clamp(33.8rem, 75%, 88rem);
      z-index: var(--z-modal);
      // height: 32.3rem;
      height: max-content;
      overflow: visible;
      margin: auto;
      border: unset;
      @extend %p-4;
      @extend %half-radius;
      @extend %pure-white-900-bg;
      &::backdrop {
        background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
        z-index: var(--z-patterns);
        opacity: 0.9;
        @include position(0, 0, null, null, fixed);
        @extend %flex-c-c;
        @extend %h-100;
        @extend %w-100;
      }
    }
    &-head {
      @extend %relative;
      .title {
        @extend %uppercase;
        @extend %font-16-pb;
        @extend %secondary-800;
        @extend %mb-6;
      }
      .btn-close {
        width: 3.4rem;
        height: 3.4rem;
        aspect-ratio: 1/1;
        @include position(-3rem, -3rem, null, null);
        @extend %circle-radius;
        @extend %flex-c-c;
        @extend %primary-300-bg;
        &::after {
          @include icon(close);
        }
      }
    }
    &-body {
      // overflow-y: auto;
      // height: calc(100% - 5.6rem);
      &-wrap {
        overflow: auto;
        max-height: calc(100vh - 16rem);
      }
      .sub-title {
        @extend %d-none;
      }
      .description {
        line-height: 1.5;
        @extend %font-14-pr;
        @extend %secondary-800;
      }
    }
  }
}
@include mq(col-lg) {
  .waf-player {
    padding-top: calc(var(--header-height) + var(--header-bottom-height));
    &::before {
      height: calc(var(--header-height) + 24.5rem);
    }
    .manhattan {
      &-section {
        .graph {
          &-listing {
            left: 4rem;
            width: calc(100% - 4rem);
            padding-left: var(--space-12);
          }
        }
      }
    }
    .related {
      &-news {
        .waf-head {
          .title {
            width: 100%;
          }
        }
      }
      &-videos {
        background-color: var(--pure-white-900);
        padding: var(--space-4);
        .waf-listing {
          background-color: unset;
          padding-block: 0;
        }
        .article-list {
          padding-top: 0;
        }
      }
      &-photos {
        .waf-listing {
          padding-block: 0;
          .article {
            &-list {
              grid-template-columns: repeat(4, 1fr);
              gap: var(--space-3);
              padding-top: 0;
              width: 100%;
              & > * {
                width: 100%;
              }
              .article-item {
                &:nth-child(4) {
                  grid-area: 1 / 4 / 2 / 5;
                }
                &:nth-child(5) {
                  grid-area: 2 / 4 / 3 / 5;
                }
              }
            }
            &-item {
              &:nth-child(3) {
                .article-description {
                  display: none;
                }
              }
              .article {
                &-meta {
                  .meta {
                    font-size: 1.1rem;
                  }
                  .meta-category {
                    font-size: 0.7rem;
                  }
                }
                &-title {
                  @include truncate(2, 12, 18);
                }
              }
            }
            &-thumbnail {
              height: 100%;
            }
          }
        }
        .waf-head {
          padding-bottom: var(--space-4);
        }
        .waf-body {
          margin-top: 0;
        }
      }
    }
  }
  .player {
    &-details {
      &:not(:first-child) {
        margin: 0;
      }
      &:first-child {
        border-radius: 0.4rem;
        // margin: var(--space-6-neg) 0 0 0;
      }
    }
    &-wrapper {
      display: grid;
      // grid-template-columns: repeat(3, 1fr);
      grid-template-columns: 30rem 1fr;
      gap: var(--space-4);
    }
    &-content {
      padding-inline: var(--space-2);
    }
    &-info-wrapper {
      grid-area: 1 / 1 / 4 / 2;
      padding-top: 0;
      margin-bottom: 0;
      &::before {
        height: 76%;
      }
      .player-name,
      .player-country {
        margin-bottom: var(--space-8);
      }
      .social-wrap {
        height: 6rem;
      }
      .social-link-anchor {
        width: 3rem;
        height: 3rem;
      }
    }
    &-image {
      width: 21rem;
      height: 21rem;
      margin-bottom: var(--space-6);
    }
    &-overview {
      grid-area: 1 / 2 / 3 / 4;
    }
    &-awards {
      --_line-number: 4;
      grid-area: 6 / 1 / 7 / 2;
    }
    &-bio-wrap {
      grid-area: 3 / 2 / 5 / 4;
      .toggle-bio {
        &::after {
          content: '';
        }
      }
    }
    &-bio {
      .content {
        font-size: 1.4rem;
      }
    }
    &-stats {
      // grid-area: 6 / 2 / 14 / 4;
      grid-area: 6 / 2 / 11 / 4;
    }
    &-name {
      .name {
        font-size: 2rem;
      }
    }
    &-meta-list {
      row-gap: var(--space-8);
      display: flex;
      flex-wrap: wrap;
    }
    &-meta-item {
      width: calc(100% / 3);
      gap: var(--space-4);
      margin-bottom: 0;
      @include flex-config(flex, column, flex-start, flex-start);
      &:not(:last-child),
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-meta-value {
      max-width: 75%;
      text-align: left;
      font-size: 1.4rem;
    }
    &-meta-title {
      font-size: 1.2rem;
    }
  }
  .represented {
    &-teams {
      grid-area: 4 / 1 / 6 / 2;
      // height: 32.5rem;
      .teams {
        &-list {
          // height: 24rem;
          max-height: 40rem;
          overflow-y: auto;
        }
      }
    }
  }
  .ad {
    &-one {
      grid-area: 5 / 2 / 6 / 4;
    }
    &-three {
      grid-area: 12 / 1 / 15 / 2;
    }
    &-four {
      grid-area: 7 / 1 / 8 / 2;
    }
    &-five {
      grid-area: 19 / 2 / 20 / 4;
    }
  }
  .trending {
    &-players {
      grid-area: 10 / 1 / 12 / 2;
      // height: 48.9rem;
    }
    // &-list {
    //   white-space: normal;
    //   display: flex;
    //   flex-flow: wrap;
    //   gap: var(--space-3);
    // }
    // &-body {
    //   height: 38.9rem;
    //   overflow-y: auto;
    // }
    // &-item {
    //   width: calc(50% - 0.8rem);
    //   height: auto;
    //   aspect-ratio: 1 / 1;
    // }
  }
  .related {
    &-news {
      grid-area: 8 / 1 / 10 / 2;
    }
    &-photos {
      // grid-area: 14 / 2 / 17 / 4;
      grid-area: 11 / 2 / 14 / 4;
    }
    &-videos {
      // grid-area: 17 / 2 / 19 / 4;
      grid-area: 14 / 2 / 19 / 4;
      .waf-listing {
        &.waf-home-video-listing .article-list {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
  .social {
    &-feed {
      grid-area: 20 / 2 / 23 / 4;
    }
  }
  .waf-footer {
    .represented-teams,
    .player-awards {
      margin-inline: var(--space-0);
    }
  }
  .no-awards {
    .ad-four {
      grid-area: 6 / 1 / 8 / 2;
    }
  }
  .no-photos {
    .related-videos {
      grid-area: 11/2/16/4;
    }
    .ad-five {
      grid-area: 16/2/17/4;
    }
    &.no-videos {
      .ad-five {
        grid-area: 11/2/12/4;
      }
    }
  }
  .no-videos {
    .ad-five {
      grid-area: 16/2/17/4;
    }
  }
  .no-news {
    .trending-players {
      grid-area: 8/1/10/2;
    }
    .ad-three {
      grid-area: 10/1/12/2;
    }
  }
  .manhattan-two {
    .player-stats {
      grid-area: 6 / 2 / 14 / 4;
    }
    .related {
      &-photos {
        grid-area: 14 / 2 / 17 / 4;
      }
      &-videos {
        grid-area: 17 / 2 / 19 / 4;
      }
    }
    .no-photos {
      .ad-five {
        grid-area: 14/2/14/4;
      }
    }
    .no-videos {
      .related-photos {
        grid-area: 15/2/17/4;
      }
      .ad-five {
        grid-area: 14/2/14/4;
      }
    }
  }
}